import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";

import type {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutCreatePayload,
    AppWorkoutUpdatePayload,
} from "@volley/data";
import { convert } from "@volley/physics";
import type { ModuleConfig } from "@volley/shared/apps/module-models";
import type { JSONObject } from "@volley/shared/common-models";

import logger from "../../../../../../log";
import fetchApi, { logFetchError } from "../../../../../../util/fetchApi";
import Loading from "../../../../../common/Loading";
import LocalizeWorkoutVisualizer from "../../../../../common/Visualizer/LocalizeWorkoutVisualizer";
import { workoutToVisualizer } from "../../../../../common/Visualizer/utils";
import { useSelectedSport } from "../../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../../hooks/PhysicsModelProvider";
import { useCurrentUser } from "../../../../../hooks/currentUser";
import { useLift } from "../../../../../hooks/useLift";
import WorkoutDeleteConfirmation from "../../../Shared/WorkoutDeleteConfirmation";
import useAppWorkouts, {
    appWorkoutToCreate,
    appWorkoutToUpdate,
} from "../../../db";
import LocalWorkouts from "../../../localWorkoutState";
import makeSafeSpinLevel from "../../util";

import EditControls from "./EditControls";
import ModuleEditDuplicateAlert from "./ModuleEditDuplicateAlert";
import ModuleEditFeedbackAlert from "./ModuleEditFeedbackAlert";
import { reducer, stripFormMetadata, validate, WorkoutForm } from "./reducer";

type SaveMode = "new" | "duplicate" | "update";

export default function ModuleEdit(): JSX.Element {
    const { appId, id } = useParams<{ appId: string; id: string }>();
    const validAppId = parseInt(appId ?? "", 10);
    const validId = parseInt(id ?? "", 10);
    const navigate = useNavigate();
    const { physicsModelName } = usePhysicsModelContext();
    const { addWorkout, deleteWorkout, updateWorkout, loadModule, getWorkout } =
        useAppWorkouts();
    const { safeHeight } = useLift();
    const { currentUser } = useCurrentUser();
    const { selected: selectedSport, getDefaultPosition } = useSelectedSport();
    const [visualizedShotIds, setVisualizedShotIds] = React.useState<
        string[] | null
    >(null);

    const [workout, workoutDispatch] = React.useReducer(reducer, null);
    const [duplicateId, setDuplicateId] = React.useState<number | null>(null);
    const [toDelete, setToDelete] = React.useState<WorkoutForm | null>(null);
    const [feedbackMessage, setFeedbackMessage] = React.useState<string | null>(
        null,
    );

    const workoutForVisualizer = React.useMemo(() => {
        const forVisualizer = workoutToVisualizer(
            workout ?? undefined,
            "standard",
            selectedSport,
        );
        if (visualizedShotIds && forVisualizer?.shots.length) {
            forVisualizer.shots = forVisualizer.shots.filter((shot) => {
                if (!shot.id) return true;
                return visualizedShotIds.includes(shot.id);
            });
        }
        return forVisualizer;
    }, [workout, selectedSport, visualizedShotIds]);
    const [hasLocalized, setHasLocalized] = React.useState(false);

    const generateNewWorkout = React.useCallback(() => {
        let position = getDefaultPosition();
        if (selectedSport === "PLATFORM_TENNIS") {
            const converted = convert.physics2localization({
                x: position.x,
                y: position.y,
                z: safeHeight * 0.0254,
            });
            position = { x: converted.x, y: converted.y, sys: "court" };
        }
        const newConfig: ModuleConfig = {
            shots: [],
            details: "",
            playerPosition: { x: 0, y: 0 },
            randomize: false,
            numberOfBalls: 1,
            sets: 1,
            interval: 3,
            intervalAfterSet: 5,
            videoSlug: "",
        };
        const newWorkout: AppWorkoutCreatePayload = {
            appId: validAppId,
            appName: "Module",
            config: newConfig as unknown as JSONObject,
            contentProviderId: null,
            copiedFromAppWorkoutId: null,
            description: "",
            extendedData: null,
            name: "",
            overview: "",
            physicsModelName,
            positionHeight: safeHeight,
            positionX: position.x,
            positionY: position.y,
            positionYaw: 0,
            sport: {
                name: selectedSport,
            },
            state: "BUILD",
        };
        return newWorkout;
    }, [
        validAppId,
        getDefaultPosition,
        physicsModelName,
        safeHeight,
        selectedSport,
    ]);

    React.useEffect(() => {
        async function fetchWorkout() {
            if (!validId) return;
            const loaded = await loadModule(validId);
            if (loaded) {
                const { workout: data } = loaded;
                const isOwner = data.workoutCollaborators.some(
                    (c) => c.role === "owner" && c.user.id === currentUser?.id,
                );
                const isEditor = data.workoutCollaborators.some(
                    (c) => c.role === "editor" && c.user.id === currentUser?.id,
                );
                if (!isOwner || !isEditor) {
                    navigate("/", { replace: true });
                }
                workoutDispatch({
                    type: "set",
                    value: {
                        ...data,
                        isOwner,
                        isEditor,
                        mode: "edit",
                    },
                });
            }
        }

        if (!validId || id === "new") {
            const newWorkout = generateNewWorkout();
            workoutDispatch({
                type: "set",
                value: {
                    ...newWorkout,
                    isOwner: true,
                    isEditor: false,
                    mode: "create",
                },
            });
        } else {
            fetchWorkout().catch((e) =>
                logFetchError(e, "Failed to fetch workout."),
            );
        }
    }, [loadModule, generateNewWorkout, navigate, currentUser, id, validId]);

    const resetToDefault = React.useCallback(async () => {
        if (!validId) {
            workoutDispatch({
                type: "set",
                value: {
                    ...generateNewWorkout(),
                    isOwner: true,
                    isEditor: false,
                    mode: "create",
                },
            });
        } else {
            const original = await getWorkout(validId);
            if (original) {
                const isOwner = original.workoutCollaborators.some(
                    (c) => c.role === "owner" && c.user.id === currentUser?.id,
                );
                const isEditor = original.workoutCollaborators.some(
                    (c) => c.role === "editor" && c.user.id === currentUser?.id,
                );
                workoutDispatch({
                    type: "set",
                    value: {
                        ...original,
                        isOwner,
                        isEditor,
                        mode: "edit",
                    },
                });
                LocalWorkouts.clear();
                setFeedbackMessage("Module reset to last saved value.");
            }
        }
    }, [generateNewWorkout, getWorkout, validId, currentUser]);

    const handleDeleteConfirmed = React.useCallback(async () => {
        if (toDelete !== null && toDelete.mode === "edit") {
            await deleteWorkout(toDelete);
            setFeedbackMessage(`Module ${toDelete.name} deleted`);
            navigate("/content/apps/workouts/user", { replace: true });
            setToDelete(null);
        }
    }, [toDelete, deleteWorkout, navigate]);

    const trainerPosition = React.useMemo(() => {
        if (!workout) return undefined;
        return {
            x: workout.positionX,
            y: workout.positionY,
            heightIn: workout.positionHeight,
            yaw: workout.positionYaw,
        };
    }, [workout]);

    const playerPosition = React.useMemo(() => {
        if (!workout?.config) return undefined;
        return (
            (workout.config as unknown as ModuleConfig).playerPosition ?? {
                x: 0,
                y: 0,
            }
        );
    }, [workout?.config]);

    const saveWorkout = React.useCallback(
        async (
            mode: SaveMode = "update",
            name?: string,
            description?: string,
        ) => {
            if (!workout || !playerPosition || !trainerPosition) return;

            const validationErrors = validate(workout);
            workoutDispatch({
                type: "validationErrors",
                value: validationErrors,
            });
            if (validationErrors) {
                logger.info("Module form validation failure", validationErrors);
                return;
            }

            const { shots: originalShots } =
                workout.config as unknown as ModuleConfig;

            const shots = originalShots.map((shot) => {
                const { launchSpeed, spinLevel, spinDirection } = shot;
                const safeSpinLevel = makeSafeSpinLevel(
                    spinLevel,
                    spinDirection,
                    launchSpeed,
                );
                return {
                    ...shot,
                    spinLevel: safeSpinLevel,
                };
            });

            const toSave =
                workout.mode === "create" || mode === "duplicate"
                    ? appWorkoutToCreate({
                          ...(workout as unknown as AppWorkout),
                          contentProviderId: null,
                      })
                    : appWorkoutToUpdate(workout as unknown as AppWorkout);

            if (name) toSave.name = name;
            if (description) toSave.description = description;
            toSave.config = {
                ...(workout.config as unknown as ModuleConfig),
                shots,
                playerPosition: { ...playerPosition },
            } as unknown as JSONObject;
            toSave.positionX = trainerPosition.x;
            toSave.positionY = trainerPosition.y;
            toSave.positionYaw = trainerPosition.yaw;

            if (mode === "new" || mode === "duplicate") {
                const saved = await addWorkout(toSave);
                if (saved !== null) {
                    logger.info(`Successfully saved new workout: ${saved.id}`);
                    if (mode === "duplicate") {
                        setDuplicateId(saved.id);
                    } else {
                        setFeedbackMessage(`${saved.name} saved!`);
                    }
                    LocalWorkouts.clear();
                    navigate(`../edit/${saved.appId}/${saved.id}`, {
                        replace: true,
                    });
                }
            } else {
                const saved = await updateWorkout(
                    toSave as AppWorkoutUpdatePayload,
                );
                if (saved) {
                    logger.info(
                        `Successfully updated existing workout: ${saved.id}`,
                    );
                    setFeedbackMessage(`${saved.name} saved!`);
                    workoutDispatch({
                        type: "set",
                        value: {
                            ...saved,
                            isOwner: true,
                            mode: "edit",
                        },
                    });
                    LocalWorkouts.clear();
                    if (workout.tags != null) {
                        try {
                            const tagIds = Object.values(workout.tags).flatMap(
                                (t) => t.map((tag) => tag.id),
                            );
                            await fetchApi(
                                `/api/app-workouts/${saved.id}/tags`,
                                "PUT",
                                {
                                    tagIds,
                                },
                            );
                        } catch (e) {
                            logFetchError(e, "Failed to update tags");
                        }
                    }
                }
            }
        },
        [
            workout,
            playerPosition,
            trainerPosition,
            addWorkout,
            updateWorkout,
            navigate,
        ],
    );

    if (!workout) {
        return <Loading />;
    }

    if (
        workout !== null &&
        !workout.workoutCollaborators.some((c) => c.user.id === currentUser?.id)
    ) {
        navigate("/", { replace: true });
    }

    return (
        <Stack
            spacing={1}
            pb={12}
            px={1}
            minHeight="calc(100vh - 66px)"
            sx={{ backgroundColor: "white" }}
        >
            <LocalizeWorkoutVisualizer
                hasLocalized={hasLocalized}
                setHasLocalized={() => setHasLocalized(true)}
                playMode="standard"
                workout={workoutForVisualizer}
                maxHeight={225}
                side="ad"
                oneShot={false}
            />
            <EditControls
                workout={workout}
                dispatch={workoutDispatch}
                onCancel={resetToDefault}
                onDone={() =>
                    saveWorkout(workout.mode === "create" ? "new" : "update")
                }
                onDuplicate={() =>
                    saveWorkout("duplicate", `Copy of ${workout.name}`)
                }
                onDelete={() => setToDelete(workout)}
                onChangeVisualizedShotIds={setVisualizedShotIds}
            />
            <ModuleEditFeedbackAlert
                message={feedbackMessage}
                onClose={() => setFeedbackMessage(null)}
            />
            <ModuleEditDuplicateAlert
                appId={workout.appId}
                duplicateId={duplicateId}
                setDuplicateId={setDuplicateId}
            />
            <WorkoutDeleteConfirmation
                workout={
                    toDelete
                        ? (stripFormMetadata(toDelete) as AppWorkout)
                        : null
                }
                onCancel={() => setToDelete(null)}
                onConfirm={() => handleDeleteConfirmed()}
            />
        </Stack>
    );
}
